import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./ScrollToTopButton.css";

const ScrollToTopButton = ({ position }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const screenHeight = window.innerHeight;

    if (scrollY > screenHeight / 2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const buttonStyle = {
    position: "fixed",
    bottom: `${position.bottom}px`,
    right: `${position.right ? `${position.right}px` : "auto"}`,
    left: `${position.left ? `${position.left}px` : "auto"}`,
  };

  return (
    <div>
      {isVisible && (
        <div
          className="scroll-to-top-button"
          style={{ ...buttonStyle }}
          onClick={scrollToTop}
        >
          <FontAwesomeIcon icon={faChevronCircleUp} />
        </div>
      )}
    </div>
  );
};

ScrollToTopButton.propTypes = {
  position: PropTypes.shape({
    bottom: PropTypes.number.isRequired,
    right: PropTypes.number,
    left: PropTypes.number,
  }),
};

ScrollToTopButton.defaultProps = {
  position: {
    bottom: 50,
    right: 50,
  },
};

export default ScrollToTopButton;
