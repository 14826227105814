import { child, get, ref as ref1, update } from "firebase/database";
import {
  addNewUserReferralCodeToAllUserReferralList,
  getDataFromRealtimeDatabase,
} from "../../../Consumer/supportFunctions/FirebaseFunctions";
import { db } from "../../../firebase";
import { addEmailToSubscriptionList } from "../firebaseFunctions/realtimeDBFunctions";
export async function findCurrentUserType(userEmail, userUid) {
  try {
    const isUnlimitedSubscriptionResult =
      await isEmailFromUnlimitedSubscribedInstitutes(userEmail);
    if (isUnlimitedSubscriptionResult.isMatching) {
      await addEmailToSubscriptionList(
        userUid,
        `${isUnlimitedSubscriptionResult.instituteKey}_btoi_users_list`
      );
      return {
        subscriptionType: "unlimitedSubscription",
        instituteKey: isUnlimitedSubscriptionResult.instituteKey
          ? isUnlimitedSubscriptionResult.instituteKey
          : null,
        instituteName: isUnlimitedSubscriptionResult.instituteName
          ? isUnlimitedSubscriptionResult.instituteName
          : null,
        logo: isUnlimitedSubscriptionResult.logo
          ? isUnlimitedSubscriptionResult.logo
          : null,
      };
    }

    const isTrialSubscriptionResult = await isEmailFromTrialInstitutes(
      userEmail
    );
    if (isTrialSubscriptionResult.isMatching) {
      return {
        subscriptionType: "trialSubscription",
        instituteKey: isTrialSubscriptionResult.instituteKey
          ? isTrialSubscriptionResult.instituteKey
          : null,
        instituteName: isTrialSubscriptionResult.instituteName
          ? isTrialSubscriptionResult.instituteName
          : null,
        logo: isTrialSubscriptionResult.logo
          ? isTrialSubscriptionResult.logo
          : null,
      };
    }

    return {
      subscriptionType: "normalConsumer",
      instituteKey: null,
      instituteName: null,
      logo: null,
    };
  } catch (error) {
    console.error("Error finding user type:", error);
    throw error;
  }
}

async function isEmailFromUnlimitedSubscribedInstitutes(userEmail) {
  const subscribedInstitutesData = await getDataFromRealtimeDatabase(
    `/UnlimitedSubscriptionDomains`
  );

  const matchingInstituteKey = Object.keys(subscribedInstitutesData).find(
    (key) => userEmail.endsWith(subscribedInstitutesData[key].domain)
  );
  return {
    isMatching: !!matchingInstituteKey,
    instituteKey: matchingInstituteKey || null,
    instituteName:
      (matchingInstituteKey &&
        subscribedInstitutesData[matchingInstituteKey].name) ||
      null,
    logo:
      (matchingInstituteKey &&
        subscribedInstitutesData[matchingInstituteKey].logo) ||
      null,
  };
}

async function isEmailFromTrialInstitutes(userEmail) {
  const trialInstitutesData = await getDataFromRealtimeDatabase(
    `/TrialSubscriptionDomains`
  );

  const matchingInstituteKey = Object.keys(trialInstitutesData).find((key) =>
    userEmail.endsWith(trialInstitutesData[key].domain)
  );

  return {
    isMatching:
      !!matchingInstituteKey &&
      trialInstitutesData[matchingInstituteKey].freeInterviewsLeft > 0,
    instituteKey: matchingInstituteKey || null,
    instituteName:
      (matchingInstituteKey &&
        trialInstitutesData[matchingInstituteKey].name) ||
      null,
    logo:
      (matchingInstituteKey &&
        trialInstitutesData[matchingInstituteKey].logo) ||
      null,
  };
}

export async function addNewUserDataToRealTimeDatabase(
  userData,
  userId,
  userReferralCode,
  profileId
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      await update(ref1(db, `${"AllUsersData"}/${userId}`), userData);
      await addNewUserReferralCodeToAllUserReferralList(
        userReferralCode,
        userId,
        "AllUserReferralList"
      );
      await addNewUserReferralCodeToAllUserReferralList(
        profileId,
        userId,
        "AllUserProfileIDList"
      );
      await addNewUserReferralCodeToAllUserReferralList(
        userId,
        userData.currentUserType,
        "AllUserUUIDAndCurrentUserTypeList"
      );
      resolve(userId);
    } catch (error) {
      console.error("Error updating value:", error);
      reject(error);
    }
  });
}

export async function editCurrentUserTypeInRealTimeDatabase(
  updatedValue,
  variablePath,
  realTimeDBPath,
  userId
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    console.log("Dwaraka edit: ", userId, realTimeDBPath);
    try {
      if (!userId) {
        reject(new Error("User ID is required to edit data."));
        return;
      }

      // Check if the userId exists in the database
      const keyRef = child(ref1(db, realTimeDBPath), userId);
      const snapshot = await get(keyRef);

      if (snapshot.exists()) {
        console.log("Dwaraka snapshot.exists(): ");
        // If the userId exists, construct the path to the specific variable
        const variablePathObject = {};
        variablePathObject[variablePath] = updatedValue;
        // Update the specific variable with the updated value
        if (realTimeDBPath == "AllUsersData") {
          await update(
            ref1(db, `${realTimeDBPath}/${userId}`),
            variablePathObject
          );
        } else {
          await update(ref1(db, `${realTimeDBPath}`), variablePathObject);
        }
        resolve(userId);
      } else {
        // If the userId does not exist, reject with an error
        reject(new Error("User ID not found in the database."));
      }
    } catch (error) {
      console.error("Error editing value:", error);
      reject(error);
    }
  });
}
