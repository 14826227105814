import { getAnalytics, logEvent } from "@firebase/analytics";
import React, { lazy, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHelmet from "../../../Global/components/PageHelmet/PageHelmet";
import useIsTabletSize from "../../../Global/customHooks/isTabletSizeHook";
const TemporaryHomePageTopSections = lazy(() =>
  import("./TemporaryHomePageTopSections/TemporaryHomePageTopSections")
);
const TemoporaryNavBar = lazy(() =>
  import("./TemoporaryNavBar/TemoporaryNavBar")
);

import { findIfThisDataInRealTimeDB } from "../../supportFunctions/FirebaseFunctions";
import "./TemporaryHomePage.css";
import TemporaryHomePageRemainingSections from "./TemporaryHomePageRemainingSections/TemporaryHomePageRemainingSections";

export default function TemporaryHomePage() {
  const { slug } = useParams();
  let navigate = useNavigate();
  const isTabletSize = useIsTabletSize();
  const analytics = getAnalytics();

  useEffect(() => {
    const checkValidReferral = async () => {
      try {
        if (slug) {
          const isValidReferral = await findIfThisDataInRealTimeDB(
            slug,
            "AllUserReferralList"
          );
          if (isValidReferral) {
            localStorage.setItem("referredById", slug);
          } else {
            navigate("/");
          }
        }
      } catch (error) {
        console.error("Error checking referral:", error);
        logEvent(analytics, "firebase_failure", {
          api_call_type: "is_referral_valid",
          error_message: error.message,
        });
        navigate("/");
      }
    };
    checkValidReferral();
  }, [slug]);
  return (
    <div className="home_page">
      <PageHelmet
        title={"EvaluAItor: #1 End-to-End AI Interview Platform"}
        description={
          "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
        }
      />
      {/* <div
        className={`mt-5 pt-5 text-center ${isLoading & isTabletSize ? "d-block" : "d-none"}`}
      >
        <div>
          <div className="home-page-preloader-lottie m-auto p-events-none">
            <Lottie options={defaultPreLoaderOptions} />
          </div>
        </div>
        <h3 className="preloader-text">
          Awakening your AI interview partner...
        </h3>
      </div> */}
      <div className="d-block">
        <TemoporaryNavBar />
        <TemporaryHomePageTopSections isTabletSize={isTabletSize} />
        <TemporaryHomePageRemainingSections isTabletSize={isTabletSize} />
      </div>
    </div>
  );
}
