import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AdminRoute from "./AdminRoute";
import UserRoute from "./UserRoute";

const CustomRouteElement = ({
  children,
  isAdminRoute = false,
  isUserRoute = false,
  hideChatBot = false,
}) => {
  useEffect(() => {
    const loadingTimeout = setTimeout(
      async () => {
        const tidioChatDiv = document.getElementById("tidio-chat-iframe");
        if (tidioChatDiv) {
          tidioChatDiv.style.transition = "opacity 0.5s ease-out";
          tidioChatDiv.style.opacity = hideChatBot ? "0" : "1";

          if (hideChatBot) {
            setTimeout(() => {
              tidioChatDiv.style.display = "none";
            }, 500);
          } else {
            tidioChatDiv.style.display = "block";
          }
        }
      },
      hideChatBot ? 2000 : 0
    );

    return () => {
      clearTimeout(loadingTimeout); // Clean up the timeout when the component unmounts
    };
  }, [hideChatBot, children]);

  let routeElement = children;

  if (isAdminRoute) {
    routeElement = <AdminRoute>{children}</AdminRoute>;
  } else if (isUserRoute) {
    routeElement = <UserRoute>{children}</UserRoute>;
  }

  return routeElement;
};

CustomRouteElement.propTypes = {
  path: PropTypes.string.isRequired,
  isAdminRoute: PropTypes.bool,
  isUserRoute: PropTypes.bool,
  hideChatBot: PropTypes.bool,
};

export default CustomRouteElement;
