import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./ProgressiveImage.css";

const ProgressiveImage = ({
  placeholderSrc,
  src,
  alt,
  width,
  height,
  extraClass,
}) => {
  ProgressiveImage.propTypes = {
    placeholderSrc: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    extraClass: PropTypes.string,
  };
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
      setLoading(false);
    };
  }, [src]);

  const customClass = loading ? "loading" : "loaded";

  return (
    <img
      loading="lazy"
      className={`image ${customClass} ${extraClass || ""}`}
      src={imgSrc}
      alt={alt || ""}
      width={width}
      height={height}
    />
  );
};

export default ProgressiveImage;
