import { getAnalytics, logEvent } from "@firebase/analytics";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo_title_r_bg_light from "../../../../Global/assets/Images/Logos/logo_title_r_bg_light.png";
import navLogo0 from "../../../../Global/assets/Images/Logos/logo_title_r_bg_none.png";
import navLogo from "../../../../Global/assets/Images/Logos/logo_title_s_bg_light.png";
import CustomLinkTag from "../../../../Global/components/CustomLinkTag/CustomLinkTag";
import useIsTabletSize from "../../../../Global/customHooks/isTabletSizeHook";
import "./TemoporaryNavBar.css";

export default function TemoporaryNavBar({
  sticky,
  restrictRedirection = false,
  useAnchorTag = false,
}) {
  TemoporaryNavBar.propTypes = {
    sticky: PropTypes.bool,
    restrictRedirection: PropTypes.bool,
    useAnchorTag: PropTypes.bool,
  };
  const analytics = getAnalytics();
  const [navFixed, setnavFixed] = useState(false);

  const isTabletSize = useIsTabletSize();

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  }, []);
  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setnavFixed(true);
    } else {
      setnavFixed(false);
    }
  };

  const logAnalyticsClickEvent = (name) => {
    logEvent(analytics, "click-event", {
      content_placement: "navbar",
      content_type: "link",
      content_name: name,
    });
  };

  return (
    <div className={`${(navFixed || sticky) && "is-sticky"}`}>
      <Navbar expand="lg" className="main-navbar-container" fixed="top">
        <Container className="tyn-appbar-wrap">
          <Navbar.Brand
            className="tyn-appbar-logo px-0"
            onClick={() => logAnalyticsClickEvent("logo_navbar")}
          >
            <CustomLinkTag
              classNames="text-decoration-none"
              restrictRedirection={restrictRedirection}
              useAnchorTag={useAnchorTag}
              to="/"
            >
              <div className="tyn-logo h-100">
                <img
                  loading="lazy"
                  alt="nav-bar-logo"
                  src={
                    (navFixed || sticky) && isTabletSize
                      ? navLogo
                      : isTabletSize
                      ? navLogo0
                      : logo_title_r_bg_light
                  }
                  className={` pb-1 tyn-logo-img h-100 ${
                    navFixed || sticky ? "visible" : ""
                  } ${!isTabletSize ? "px-0" : "px-3"}`}
                />
              </div>
            </CustomLinkTag>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  );
}
