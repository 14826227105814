import { useEffect, useState } from "react";
import { useDebounce } from "./debounceHook";

const useIsTabletSize = () => {
  const [isTabletSize, setIsTabletSize] = useState(() => checkIsTabletSize());

  useEffect(() => {
    const handleResize = () => {
      setIsTabletSize(checkIsTabletSize());
    };

    const debouncedResize = useDebounce(handleResize, 200); // Adjust debounce time as needed

    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, []);

  return isTabletSize;
};
const checkIsTabletSize = () => {
  return window.matchMedia("(min-width: 992px)").matches;
};
export default useIsTabletSize;
