import cf_img_2_placeholder from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/cf-img-2-placeholder.webp";
import cf_img_2 from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/cf-img-2.webp";
import cf_img_3_placeholder from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/cf-img-3-placeholder.webp";
import cf_img_3 from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/cf-img-3.webp";
import cf_img_6_placeholder from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/cf-img-6-placeholder.webp";
import cf_img_6 from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/cf-img-6.webp";
import codingRound_placeholder from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/codingRound-placeholder.webp";
import codingRound from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/codingRound.webp";
import hrInterview_placeholder from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/hrInterview-placeholder.webp";
import hrInterview from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/hrInterview.webp";
import onlineTest_placeholder from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/onlineTest-placeholder.webp";
import onlineTest from "../../../Global/assets/Images/HomePage/TabImages/TabContentImages/onlineTest.webp";

import cf_img_2_b from "../../../Global/assets/Images/HomePage/TabImages/TabNavImages/cf-img-2-b.svg";
import cf_img_3_b from "../../../Global/assets/Images/HomePage/TabImages/TabNavImages/cf-img-3-b.svg";
import cf_img_4_b from "../../../Global/assets/Images/HomePage/TabImages/TabNavImages/cf-img-4-b.svg";
import cf_img_5_b from "../../../Global/assets/Images/HomePage/TabImages/TabNavImages/cf-img-5-b.svg";
import cf_img_6_b from "../../../Global/assets/Images/HomePage/TabImages/TabNavImages/cf-img-6-b.svg";
import cf_img_7_b from "../../../Global/assets/Images/HomePage/TabImages/TabNavImages/cf-img-7-b.svg";
export const homePageTabsNavData = [
  {
    id: 0,
    imageSrc: cf_img_2_b,
    imageDescription: "Technical Interview",
    badgeText: "AI",
  },
  {
    id: 1,
    imageSrc: cf_img_3_b,
    imageDescription: "Resume Round Interview",
    badgeText: "AI",
  },
  {
    id: 2,
    imageSrc: cf_img_5_b,
    imageDescription: "HR \n Interview",
    badgeText: "AI",
  },
  {
    id: 3,
    imageSrc: cf_img_7_b,
    imageDescription: "DSA \n Interview",
    badgeText: "AI",
  },
  {
    id: 4,
    imageSrc: cf_img_4_b,
    imageDescription: "Online MCQ \n Round",
  },
  {
    id: 5,
    imageSrc: cf_img_6_b,
    imageDescription: "Online Coding Round",
  },
];

export const homePageTabsNavContainerData = [
  {
    id: 0,
    imageSrc: cf_img_2,
    imgPlaceholderSrc: cf_img_2_placeholder,
    title: "Technical Interview",
    description:
      "Streamline the hiring process by conducting comprehensive technical interviews through our AI tool, efficiently assessing candidate skills and compatibility. Get detailed in-depth insights and make informed hiring decisions effortlessly.",
    btnTitle: "Try It Out",
    btnLink: "/practice",
  },
  {
    id: 1,
    imageSrc: cf_img_3,
    imgPlaceholderSrc: cf_img_3_placeholder,
    title: "Resume Review Round",
    description:
      "Customize interview questions to match your specific needs. Our AI recruiting assistant will seamlessly present these questions in a voice format during candidate interviews. Achieve a personalized and efficient interviewing process with customization capabilities.",
    btnTitle: "Try It Out",
    btnLink: "/practice",
  },
  {
    id: 2,
    imageSrc: hrInterview,
    imgPlaceholderSrc: hrInterview_placeholder,
    title: "HR Interview with AI",
    description:
      "Revolutionize the hiring process by conducting tailored resume reviews, ensuring each candidate's experience and skills are meticulously evaluated to match the company's specific needs. Our AI-driven customization capabilities enable a personalized and efficient assessment, accelerating your recruitment journey.",
    btnTitle: "Try It Out",
    btnLink: "/practice",
  },
  {
    id: 3,
    imageSrc: cf_img_6,
    imgPlaceholderSrc: cf_img_6_placeholder,
    title: "DSA Round with AI",
    description:
      "Send automatic private interview links to candidates through emails and WhatsApp by adding their details. Additionally, copy and share common public interview links for consecutive interviews or mass interviews.",
    btnTitle: "Try It Out",
    btnLink: "/practice",
  },
  {
    id: 4,
    imageSrc: onlineTest,
    imgPlaceholderSrc: onlineTest_placeholder,
    title: "Online MCQ Test",
    description:
      "Transcription meticulously captures every spoken word, guaranteeing accurate representation and thorough documentation. This accelerates the interview analysis process, enabling HR teams to collaboratively extract critical insights and make well-informed decisions promptly.",
    btnTitle: "Try It Out",
    btnLink: "/practice",
  },
  {
    id: 5,
    imageSrc: codingRound,
    imgPlaceholderSrc: codingRound_placeholder,
    title: "Online Coding Round",
    description:
      "Receive a comprehensive feedback report meticulously evaluating candidates, including ratings for both soft skills and technical competencies. Our platform furnishes proctoring data to assess candidate integrity during interviews, ensuring a thorough and transparent evaluation process.",
    btnTitle: "Try It Out",
    btnLink: "/practice",
  },
];
