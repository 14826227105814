import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faTelegram,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faEnvelopeOpen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo_s_bg_blue from "../../../../Global/assets/Images/Logos/logo_s_bg_blue.png";
import logo_s_bg_light from "../../../../Global/assets/Images/Logos/logo_s_bg_light.png";
import ScrollToTopButton from "../../../../Global/components/Buttons/ScrollToTopButton/ScrollToTopButton";
import ProgressiveImage from "../../../../Global/components/ProgressiveImage/ProgressiveImage";
import ProgressiveImageBackground from "../../../../Global/components/ProgressiveImageBackground/ProgressiveImageBackground";
import HomeFeatureTabs from "../../../../Global/components/Tabs/HomeFeatureTabs/HomeFeatureTabs";
import puzzleIcon from "../../../Assets/Images/HomePage/ResumeEvaluAItionSection/puzzle.webp";
import resumeIcon from "../../../Assets/Images/HomePage/ResumeEvaluAItionSection/resume.webp";
import scoreCardIcon from "../../../Assets/Images/HomePage/ResumeEvaluAItionSection/scorecard.webp";
import bgImg2 from "../../../Assets/Images/HomePage/bgImg2.webp";
import bgPattern from "../../../Assets/Images/HomePage/bgPattern.png";
import bgPatternLight from "../../../Assets/Images/HomePage/bgPatternLight.png";
import calmness from "../../../Assets/Images/HomePage/calmness.png";
import confidence from "../../../Assets/Images/HomePage/confidence.png";
import empowerment from "../../../Assets/Images/HomePage/empowerment.png";
import handsImagePlaceholder from "../../../Assets/Images/HomePage/handsImage-placeholder.webp";
import handsImage from "../../../Assets/Images/HomePage/handsImage.webp";
import oldAndNewWayConsumer_placeholder from "../../../Assets/Images/HomePage/oldAndNewWayConsumer-placeholder.webp";
import oldAndNewWayConsumer from "../../../Assets/Images/HomePage/oldAndNewWayConsumer.webp";
import abhishek from "../../../Assets/Images/TeamImages/abhishek.webp";
import simone from "../../../Assets/Images/TeamImages/simone.webp";
import {
  homePageTabsNavContainerData,
  homePageTabsNavData,
} from "../../../Assets/JsonData/homePageData";
import { HeroScrollDemo } from "../../../components/ScrollAnimation";
import TemporaryFooter from "../TemporaryFooter/TemporaryFooter";
import "./TemporaryHomePageRemainingSections.css";

export default function HomePageRemainingSections({ isTabletSize }) {
  HomePageRemainingSections.propTypes = {
    isTabletSize: PropTypes.bool,
  };
  // const defaultDIPLottieOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: DIPHomeAnimation,
  // };

  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const handleHover1 = () => {
    setIsHovered1(!isHovered1);
  };
  const handleHover3 = () => {
    setIsHovered3(!isHovered3);
  };

  const openWhatsappChat = () => {
    const phoneNumber = "+918367770909";

    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="mont-400">
      <section
        id="interviewlotteanimaiton features"
        className="section iphoneSection  text-center bg-light"
      >
        <div className="px-2 px-md-4 px-lg-8 py-2 md:py-24 mx-auto text-center col-lg-8">
          <p className="text-lg sm:text-3xl mont-700 uppercase text-center mb-2">
            How it Works
          </p>
          <div className="section-title-border mt-lg-3" />
          <p className="section-subtitle font-secondary text-center pt-1 pt-md-2 pt-lg-4 text-muted text-muted">
            EvaluAItor brings forth a new way to vet and hire your desired
            Talent.
          </p>
          <ProgressiveImage
            src={oldAndNewWayConsumer}
            placeholderSrc={oldAndNewWayConsumer_placeholder}
            alt="old-and-new-way"
            width="100%"
            extraClass="oldAndNewWay-img"
          />
        </div>
      </section>
      <ProgressiveImageBackground
        id="buildyourdreamwebsite"
        src={handsImage}
        placeholderSrc={handsImagePlaceholder}
        alt="Description"
        classNames="section section-lg bg-web-desc"
      >
        <div className="bg-overlay" />
        <div className="container wisdom-section">
          <div className="row">
            <div className="text-center col-lg-12">
              <h4 className="text-white text-lg mb-3 sm:text-3xl mont-700 uppercase text-center introducing-live-heading">
                YOU DESERVE
              </h4>
              <div className="section-title-border mt-lg-3 bg-white"></div>
              <p className="pt-3 home-desc">
                Ace your interviews with readiness, anticipation, and serenity.
                Elevate your interview game and claim your success with
                <strong> EvaluAItor.</strong>
              </p>
              <div className="mt-3 mb-2 row">
                <div className={`${isTabletSize && "plan-line"} col-lg-4`}>
                  <div className="text-center process-box">
                    <img
                      loading="lazy"
                      src={confidence} // Use the imported SVG directly
                      className="mt-3 w-auto h-auto you-deserve-img"
                      alt="interview-process-img"
                    />

                    <p className="pt-3 text-light h4">Confidence</p>
                    <p className="home-desc">
                      Walk into any interview knowing you&apos;re prepared.
                    </p>
                  </div>
                </div>
                <div className={`${isTabletSize && "plan-line"} col-lg-4`}>
                  <div className="text-center process-box">
                    <img
                      loading="lazy"
                      src={empowerment}
                      className="mt-3 w-auto h-auto you-deserve-img"
                      alt="interview-process-img"
                    />
                    <p className="pt-3 text-light h4">Empowerment</p>
                    <p className="home-desc">
                      Feel in control, anticipating the interview&apos;s
                      direction.
                    </p>
                  </div>
                </div>
                <div className={`${isTabletSize && "no-plan-line"} col-lg-4`}>
                  <div className="text-center process-box">
                    <img
                      loading="lazy"
                      src={calmness}
                      className="mt-3 w-auto h-auto you-deserve-img"
                      alt="interview-process-img"
                    />
                    <p className="pt-3 text-light h4">Calmness</p>
                    <p className="home-desc">
                      Eliminate the anxiety, knowing you&apos;ve trained with
                      the best.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-pattern-effect">
          <img loading="lazy" src={bgPattern} alt="dorsin" className="dorsin" />
        </div>
      </ProgressiveImageBackground>
      <section id="Resume Analysis" className="section iphoneSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h5 className="text-lg sm:text-3xl mb-3 mont-700 uppercase text-center introducing-live-heading">
                YOU VS 249 APPLICANTS
              </h5>
              <div className="section-title-border mt-lg-3" />
              <p className="section-subtitle text-muted text-center pt-4 font-secondary home-para">
                According to Glassdoor, every corporate job attracts{" "}
                <strong> 250 applicants on average. </strong> Gain an edge over
                others by making your resume stand out with a suite of features
                designed to help you revamp Your Resume in 60 Seconds.
              </p>
            </div>
          </div>
          <div className="mt-2 mt-lg-4 row">
            <div className="mt-3 col-lg-4">
              <div className="services-box text-center hover-effect">
                <img
                  loading="lazy"
                  src={scoreCardIcon}
                  alt="home-hero-image"
                  className="home-hero-image"
                  id="hover-effect"
                />
                <p className="pt-3 h4">Swift Scorecard</p>
                <p className="pt-3 text-muted home-para">
                  Elevate in just 60 Seconds with AI analysis, scoring, and
                  tailored tips for instant resume enhancement.
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-4">
              <div className="services-box text-center hover-effect">
                <img
                  loading="lazy"
                  src={resumeIcon}
                  alt="home-hero-image"
                  className="home-hero-image"
                  id="hover-effect"
                />
                <p className="pt-3 h4">Job-Precision Editing</p>
                <p className="pt-3 text-muted home-para">
                  Tailor with Precision. Download PDF edited to perfection based
                  on job role.
                </p>
              </div>
            </div>
            <div className="mt-3 col-lg-4">
              <div className="services-box text-center hover-effect">
                <img
                  loading="lazy"
                  src={puzzleIcon}
                  alt="home-hero-image"
                  className="home-hero-image"
                  id="hover-effect"
                />
                <p className="pt-3 h4">Career Compatibility Check</p>
                <p className="pt-3 text-muted home-para">
                  Craft a standout resume seamlessly. Detailed insights on
                  strengths, weaknesses, and desired role suitability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HomeFeatureTabs
        tabsNavData={homePageTabsNavData}
        tabsNavContainerData={homePageTabsNavContainerData}
        showCTAs={false}
      />
      <section className="">
        <div className="w-full max-w-[920px] mx-auto px-md-8 px-2 md:pt-24">
          <div className="text-center">
            <p className="text-lg sm:text-3xl mont-700 mb-3 uppercase text-center introducing-live-heading">
              INTRODUCING AI BASED LIVE DSA SOLVING INTERVIEWS
            </p>
            <div className="section-title-border mt-lg-3" />
            <p className="section-subtitle font-secondary text-center pt-2 pt-lg-4 text-muted text-muted">
              This round will be similar to a real coding interview. Here, the
              AI Bot will act as the interviewer and assist you when you
              encounter difficulties, just like in a real interview.
            </p>
            <div className="flex flex-col justify-center items-center flex-wrap my-3">
              <div className="languages-div my-3 p-1">
                <span className="languages-div-btn ps-4 pe-3 py-2 transition-all ">
                  Java
                </span>
                <span className="languages-div-btn px-3 py-2 transition-all  ">
                  Go
                </span>
                <span className="languages-div-btn px-3 py-2 transition-all ">
                  Node.js
                </span>
                <span className="languages-div-btn px-3 py-2 transition-all ">
                  PHP
                </span>
                <span className="languages-div-btn px-3 py-2 transition-all ">
                  Python
                </span>
                <span className="languages-div-btn px-3 py-2 transition-all ">
                  Ruby
                </span>
                <span className="languages-div-btn ps-3 pe-4 py-2 transition-all ">
                  C#(.Net)
                </span>
              </div>
            </div>
          </div>
        </div>
        <HeroScrollDemo />
      </section>
      {/* <section
        id="interviewlotteanimaiton features"
        className="section iphoneSection"
      >
        <div className="container">
          <div className="vertical-content row">
            <div className="col-lg-6 p-3">
              <div className="features-box">
                <p className="h3">DIP - Your Daily Dose of Interview</p>
                <p className="text-muted web-desc h6 home-para">
                  Dive into daily interview challenges and streak rewards.
                  Ignite your coding prowess, conquer time constraints, and open
                  doors to interview success!
                </p>
                <ul className="text-muted list-unstyled mt-4 features-item-list">
                  <li>
                    <strong>Daily DSA Challenges:</strong> Elevate interview
                    prep with daily challenges, honing problem-solving skills
                    under time constraints.
                  </li>
                  <li>
                    <strong>Interview Simulations:</strong> Receive valuable
                    tips in simulated interviews, refining your approach and
                    code optimization skills.
                  </li>
                  <li>
                    <strong>Master Time Management:</strong> Tackle daily timed
                    challenges, mirroring real interviews for effective time
                    management.
                  </li>
                  <li>
                    <strong>30-Day Streak Rewards:</strong> Unlock mentor
                    sessions with MAANG employees after a 30-day streak,
                    boosting interview readiness.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="features-img features-right text-end m-auto p-events-none">
                <Lottie
                  className={`interview-potential-lottie ${
                    isTabletSize ? "tablet" : ""
                  }`}
                  options={defaultDIPLottieOptions}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section
        id="letsgetstarted"
        className="section section-lg bg-get-start iphoneSection"
        style={{
          backgroundImage: `url(${bgImg2})`,
        }}
      >
        <div className="bg-overlay" />
        <div className="container resume-analysis-section py-4 md:py-24">
          <div className="row">
            <div className="text-center col-lg-8 offset-lg-2 mb-5">
              <h6 className="text-lg sm:text-3xl mont-700 uppercase text-white mb-2">
                Referral system and Mentorship
              </h6>
              <div className="section-title-border mt-lg-3 bg-white" />
              <p className="section-subtitle font-secondary text-center pt-4 home-desc-color">
                Elevate your journey with our Referral System and Mentorship!
                Refer friends for an instant 100 credits upon signup. When your
                referral completes their first interview, you earn an additional
                100 credits. Achieve two consecutive high scores for a job
                referral and mentorship from Maang employees. Grow together and
                reap the rewards!
              </p>
            </div>
          </div>
        </div>
        <div className="bg-pattern-effect">
          <img
            loading="lazy"
            src={bgPatternLight}
            alt="interview-process-img"
            className="w-auto h-auto"
          />
        </div>
      </section>
      <section id="builtBy" className="text-center container iphoneSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <p className="text-lg sm:text-3xl mont-700 uppercase text-center h1">
                BUILT BY
              </p>
              <div className="section-title-border my-3" />
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-lg-3 justify-content-center align-items-center">
          <div className="column col-lg-4">
            <div
              className={`card mt-3 ${isHovered1 ? "hovered" : ""}`}
              onMouseEnter={handleHover1}
              onMouseLeave={handleHover1}
            >
              <div className="img-container">
                <img
                  loading="lazy"
                  src={simone}
                  alt="director"
                  className="built-by-img"
                />
              </div>
              <p className="h3">Simone Singh</p>
              <p className="role">Director</p>
              <div className="icons">
                <Link
                  aria-label="Linkedin link"
                  to="https://www.linkedin.com/in/simone-singh-67b204263/"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </Link>
                <Link
                  aria-label="Github link"
                  to="https://github.com/simone-28"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faGithub} />
                </Link>
                <Link
                  aria-label="Instagram link"
                  to="https://www.instagram.com/_28simone_/"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
                <Link
                  aria-label="Mail link"
                  to="mailto:simone28singh@gmail.com"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </Link>
              </div>
            </div>{" "}
          </div>
          <div className=" col-lg-4">
            <div
              className={`card mt-3 ${isHovered3 ? "hovered" : ""}`}
              onMouseEnter={handleHover3}
              onMouseLeave={handleHover3}
            >
              <div className="img-container">
                <img
                  loading="lazy"
                  src={isHovered3 ? logo_s_bg_light : logo_s_bg_blue}
                  alt={isHovered3 ? "mainBot" : "mainBot"}
                  className="built-by-img"
                />
              </div>
              <p className="h3">AI Bot</p>
              <p className="role">I run the show! They? Clueless 😜</p>
              <div className="icons">
                <Link
                  aria-label="Linkedin link"
                  to="https://www.linkedin.com/company/evaluaitor/"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </Link>
                <Link aria-label="Github link" to="/">
                  <FontAwesomeIcon icon={faGithub} />
                </Link>
                <Link aria-label="instagram link" to="/">
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
                <Link
                  aria-label="Mail link"
                  to="mailto:contact@evaluaitor.com"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className={`card mt-3 ${isHovered1 ? "hovered" : ""}`}
              onMouseEnter={handleHover1}
              onMouseLeave={handleHover1}
            >
              <div className="img-container">
                <img
                  loading="lazy"
                  src={abhishek}
                  alt="director"
                  className="built-by-img"
                />
              </div>
              <p className="h3">Abhishek Chakinala</p>
              <p className="role">Director</p>
              <div className="icons">
                <Link
                  aria-label="Github link"
                  to="https://www.linkedin.com/in/abhishek-chakinala-6627802b1/"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </Link>
                <Link
                  aria-label="Linkedin link"
                  to="https://twitter.com/AChakinala52488"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faXTwitter} />
                </Link>
                <Link
                  aria-label="Instagram link"
                  to="https://www.instagram.com/abhishekchakinala/"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </Link>
                <Link
                  aria-label="Mail link"
                  to="mailto:abhishek.chakinala.3@gmail.com"
                  target="blank"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center mt-3">
            <Link className={`btn btn-primary mt-4  w-auto`} to="/team">
              Meet the Team <ArrowForwardIcon />
            </Link>
          </div>
        </div>
      </section>
      <section className="contact-social bg-light">
        <div className="container">
          <div className="align-items-center row">
            <div className="col-lg-6">
              <ul
                className="list-inline social mt-2 mt-lg-3 mb-0 mb-lg-2"
                data-href="https://evaluaitor.com/"
              >
                <li className="list-inline-item">
                  <a
                    className="social-icon"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/Giftshub/"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="footer-social-icons"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://twitter.com/EvaluAItor"
                    className="twitter-follow-button social-icon"
                    data-show-count="false"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      className="footer-social-icons"
                    />
                  </a>
                  <script
                    async
                    src="https://platform.twitter.com/widgets.js"
                  ></script>
                </li>
                <li className="list-inline-item">
                  <a
                    className="social-icon"
                    href="https://www.linkedin.com/company/97217425"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="footer-social-icons"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="social-icon"
                    href="https://www.instagram.com/evaluaitor/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="footer-social-icons"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="social-icon" href="/">
                    <FontAwesomeIcon
                      icon={faTelegram}
                      className="footer-social-icons"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="social-icon"
                    href="https://www.youtube.com/channel/UCxonHFREVJg09LDpghdzaqA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      className="footer-social-icons"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="mt-4 col-lg-3">
              <p
                className="contact-title mb-0 pointer-cursor"
                onClick={openWhatsappChat}
              >
                <FontAwesomeIcon icon={faPhone} />
                &nbsp;+91 836 777 0909
              </p>
            </div>
            <div className="mt-4 text-end col-lg-3">
              <Link
                aria-label="Mail link"
                to="mailto:contact@evaluaitor.com"
                target="blank"
                className="text-decoration-none text-dark"
              >
                <p className="contact-title mb-0">
                  <FontAwesomeIcon icon={faEnvelopeOpen} />
                  &nbsp; Contact Us
                </p>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTopButton position={{ bottom: 50, left: 25 }} />
      <TemporaryFooter isTabletSize={isTabletSize} />
    </div>
  );
}
