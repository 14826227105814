import React, { useEffect } from "react";

const SuspenseFallbackComponent = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="app-preloader-div">
      <h1 className="preloader-index-text">EvaluAItor</h1>
      <h2 className="preloader-index-text-sub">
        Awakening your AI interview partner...
      </h2>
      <h3 className="d-none">Mock Interviews</h3>
      <h4 className="d-none">Software Engineer Interview</h4>
      <h5 className="d-none">Interview Preparation</h5>
      <h6 className="d-none">Job Interview Success</h6>
    </div>
  );
};
export default SuspenseFallbackComponent;
