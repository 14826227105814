import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./ProgressiveImageBackground.css";

const ProgressiveImageBackground = ({
  placeholderSrc,
  src,
  width,
  height,
  classNames,
  children,
}) => {
  ProgressiveImageBackground.propTypes = {
    placeholderSrc: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.node,
    classNames: PropTypes.string,
  };
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
      setLoading(false);
    };
  }, [src]);

  const customClass = loading ? "loading" : "loaded";

  const backgroundStyle = {
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "-40px",
    width,
    height,
  };

  return (
    <div
      className={`image ${customClass} ${classNames}`}
      style={backgroundStyle}
    >
      {children}
    </div>
  );
};

export default ProgressiveImageBackground;
