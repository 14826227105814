import PropTypes from "prop-types";
import React from "react";
import "./HomeTabsNavCard.css";

export default function HomeTabsNavCard({
  imageSrc,
  imageDescription,
  isActive,
  onClick,
  badgeText,
}) {
  return (
    <div
      onClick={onClick}
      onMouseEnter={onClick}
      onMouseLeave={onClick}
      className={`cf-card position-relative ${
        isActive ? "cf-active" : "cf-inactive"
      }`}
    >
      {badgeText && (
        <span className="badge-text rounded-pill px-2 px-md-3 badge">
          {badgeText}
        </span>
      )}
      <img className="cf-card-img mb-2 " src={imageSrc} />
      <div className="__className_71b52a pre-line">{imageDescription}</div>
    </div>
  );
}
HomeTabsNavCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageDescription: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  badgeText: PropTypes.string,
};
