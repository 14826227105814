import { getAnalytics, logEvent } from "@firebase/analytics";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CustomLinkTag from "../../../../Global/components/CustomLinkTag/CustomLinkTag";
import { addEmailToSubscriptionList } from "../../../../Global/utils/firebaseFunctions/realtimeDBFunctions";
import "./TemporaryFooter.css";

export default function TemporaryFooter({
  isTabletSize,
  restrictRedirection = false,
  useAnchorTag = false,
}) {
  TemporaryFooter.propTypes = {
    isTabletSize: PropTypes.bool,
    restrictRedirection: PropTypes.bool,
    useAnchorTag: PropTypes.bool,
  };
  const analytics = getAnalytics();
  const [emailForSubscription, setEmailForSubscription] = useState("");
  const [isSubscriptionEmailValid, setIsSubscriptionEmailValid] =
    useState(false);

  const logAnalyticsClickEvent = (name) => {
    logEvent(analytics, "click-event", {
      content_placement: "footer",
      content_type: "link",
      content_name: name,
    });
  };

  return (
    <div>
      <footer className="footer section theme-footer">
        <div className="container footer-container-section">
          <div className="row justify-content-between">
            <div className="mt-4 col-lg-3 col-md-4 col-6">
              <div className="text-muted">
                <ul className="list-unstyled footer-list">
                  <li>
                    <CustomLinkTag
                      restrictRedirection={restrictRedirection}
                      useAnchorTag={useAnchorTag}
                      target="blank"
                      to="mailto:contact@evaluaitor.com"
                      onClick={() => logAnalyticsClickEvent("contact_footer")}
                    >
                      Contact
                    </CustomLinkTag>
                  </li>
                  <li>
                    <CustomLinkTag
                      restrictRedirection={restrictRedirection}
                      useAnchorTag={useAnchorTag}
                    >
                      <strong>Address</strong>: Hyderabad
                    </CustomLinkTag>
                    <br />
                    <CustomLinkTag
                      restrictRedirection={restrictRedirection}
                      useAnchorTag={useAnchorTag}
                    >
                      India, 500018
                    </CustomLinkTag>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-4 col-lg-3 col-md-4 col-6">
              <h4>Subscribe</h4>
              Stay in the loop for updates...
              <form className="subscribe">
                <input
                  placeholder="Your Email"
                  className="form-control text-secondary"
                  value={emailForSubscription}
                  onClick={() =>
                    logAnalyticsClickEvent("email_for_subscription_footer")
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setEmailForSubscription(inputValue);
                    const emailPattern =
                      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                    setIsSubscriptionEmailValid(emailPattern.test(inputValue));
                  }}
                />
                <button
                  aria-label="emailSubscribe"
                  disabled={!emailForSubscription || !isSubscriptionEmailValid}
                  className="submit"
                  onClick={async (e) => {
                    e.preventDefault();
                    await addEmailToSubscriptionList(
                      emailForSubscription,
                      "EmailSubscriptionList"
                    );
                    toast.success(`Subscription successfull   🎉`);
                    setEmailForSubscription("");
                  }}
                >
                  {" "}
                  <ArrowForwardIcon />
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <div
        className={`footer-alt section profile-footer ${
          !isTabletSize && "text-center"
        }`}
      >
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-4 mx-0 px-0">
              <p className="copy-rights text-muted">
                2024 © <strong>AI.VONE TECHNOLOGIES PRIVATE LIMITED</strong>
              </p>
            </div>
            <div className="col-lg-2 mx-0 px-0"></div>
            <div className="col-lg-3 mx-0 px-0"></div>
            <div className="col-lg-3 mx-0 px-0">
              <p className="copy-rights text-light fw-bold">
                MADE WITH ❤️ IN INDIA
              </p>
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
}
