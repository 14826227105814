import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = ({ title, description }) => {
  PageHelmet.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
  };
  return (
    <Helmet>
      <meta httpEquiv="Content-Type" content="text/plain; charset=utf-8" />
      <title>{`EvaluAItor: ${title}`}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta
        name="keywords"
        content="Mock Interviews, AI Interview Practice, Software Engineer Interview, Interview Preparation, AI Interviewer, Interview Skills, Technical Interview, Resume Analysis, Personalized Feedback, Interview Coaching, AI-Powered Interviews, Technical Questions, Algorithm Questions, Interview Practice Platform, Job Interview Success"
      />
      <meta name="language" content="EN" />
    </Helmet>
  );
};

export default PageHelmet;
