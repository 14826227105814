import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const UserRoute = ({ children }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const handleAuthStateChanged = async (user) => {
    if (!user || !user.uid) {
      dispatch({
        type: "LOGIN_REDIRECT",
        payload: pathname,
      });
      navigate("/login");
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChanged);

    return () => unsubscribe(); // Cleanup on component unmount
  }, [auth, navigate, handleAuthStateChanged]);

  return children;
};
export default UserRoute;
