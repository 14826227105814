import { get, ref as ref1, set, update } from "firebase/database";
import { db } from "../../../firebase";
export async function findIfThisDataInRealTimeDB(data, realTimeDBPath) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const emailList = snapshot.val() || {};
      return emailList[data];
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error finding data in Realtime DB:", error);
    return false;
  }
}

export async function addEmailToSubscriptionList(data, realTimeDBPath) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);
    console.log("DWARAKA subscription snapshot: ", snapshot);

    // Check if the email already exists in the list
    var emailList = [];
    if (snapshot.exists()) {
      emailList = snapshot.val() || [];

      if (!emailList.includes(data)) {
        emailList.push(data);

        // Use 'set' to update the data in the database
        await set(ref1(db, realTimeDBPath), emailList);
      } else {
        console.log("Email already exists in EmailSubscriptionList:", data);
      }
    } else {
      emailList.push(data);
      await set(ref1(db, realTimeDBPath), emailList);
    }
  } catch (error) {
    console.error("Error adding email to subscription list:", error);
  }
}

export async function addReportAQuestionToRealTimeDB({
  questionId,
  reportData,
  dBPath,
}) {
  const realTimeDBPath = `${dBPath}/${questionId}`;

  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    // Check if the question already exists in the list
    if (snapshot.exists()) {
      const existingReportData = snapshot.val();
      if (Array.isArray(existingReportData)) {
        // If existing data is an array, append new reportData to it
        await set(ref1(db, realTimeDBPath), [
          ...existingReportData,
          reportData,
        ]);
      } else {
        // If existing data is not an array, create a new array with existing data and new reportData
        await set(ref1(db, realTimeDBPath), [existingReportData, reportData]);
      }
    } else {
      // If the question does not exist, create a new array with the reportData
      await set(ref1(db, realTimeDBPath), [reportData]);
    }
  } catch (error) {
    console.error(
      "Error adding objective question report to Realtime Database:",
      error
    );
  }
}

export async function fetchJsonDataAsArrayFromRealTimeDBPath({
  realTimeDBPath,
}) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const reportedQuestions = snapshot.val();
      console.log("DWaraka Siri reportedQuestions: ", reportedQuestions);
      // Extract keys and values from reportedQuestions object
      const internalObjectsArray = Object.entries(reportedQuestions).map(
        ([key, value]) => ({
          questionId: key,
          reportData: value,
        })
      );
      console.log("DWaraka Siri reportedQuestions: ", internalObjectsArray);
      return internalObjectsArray;
    } else {
      console.log("No reported questions found in the database.");
      return [];
    }
  } catch (error) {
    console.error(
      "Error fetching reported questions from Realtime Database:",
      error
    );
    return [];
  }
}

export async function addNewDataForCompany({ realTimeDBPath, data }) {
  try {
    await update(ref1(db, realTimeDBPath), data);
  } catch (error) {
    console.error("Error updating value:", error);
  }
}

// Function to add new users data for company
export async function addNewUsersDataForCompany({ realTimeDBPath, data }) {
  try {
    const dbRef = ref1(db, realTimeDBPath);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      // If data already exists, merge new candidates while ensuring uniqueness
      const existingData = snapshot.val();
      if (
        existingData.valid_users_mails &&
        Array.isArray(existingData.valid_users_mails)
      ) {
        const existingEmails = new Set(
          existingData.valid_users_mails.map((item) => item.email)
        );
        data.valid_users_mails = [
          ...existingData.valid_users_mails,
          ...data.valid_users_mails.filter(
            (item) => !existingEmails.has(item.email)
          ),
        ];
      }
    }
    await update(dbRef, data);
  } catch (error) {
    console.error("Error updating value:", error);
  }
}
