import PropTypes from "prop-types";
import React, { useState } from "react";
import bgPatternLight from "../../../assets/Images/backgrounds/bgPatternLight.png";
import card_flipper_bg from "../../../assets/Images/backgrounds/card_flipper_bg.webp";
import HomeTabsNavCard from "../../Cards/HomeTabsNavCard/HomeTabsNavCard";
import HomeTabsNavDataCard from "../../Cards/HomeTabsNavDataCard/HomeTabsNavDataCard";
import "./HomeFeatureTabs.css";
export default function HomeFeatureTabs({
  tabsNavData,
  tabsNavContainerData,
  showCTAs = true,
}) {
  const [activeTab, setActiveTab] = useState(
    tabsNavData ? tabsNavData[0]?.id : null
  );
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };
  return (
    <section
      id="Resume Analysis"
      className="section iphoneSection card-flipper-container bg-light pt-0"
    >
      <div className="cf-bg-container">
        <img
          alt="card-flipper-bg"
          width={100}
          height={100}
          decoding="async"
          data-nimg={1}
          className="card-flip-bg"
          style={{ color: "transparent" }}
          src={card_flipper_bg}
        />
        <div className="bg-pattern-effect">
          <img
            loading="lazy"
            src={bgPatternLight}
            alt="interview-process-img"
            className="w-auto h-auto"
          />
        </div>
        <div className="bg-overlay1"></div>
        <div className="cf-title-box">
          <h2 className="cf-title text-lg sm:text-3xl mont-700 mb-3 uppercase text-center introducing-live-heading">
            OUR END-TO-END AI TOOLS
          </h2>
          <div className="section-title-border mt-lg-3 bg-white"></div>
          <div className="pt-3 section-desc px-2">
            Enhance your interview experience with EvaluAItor&apos;s AI
            Interview Tool
          </div>
        </div>
      </div>
      <div className="cf-content-container">
        <div className="cf-card-container px-0">
          {tabsNavData &&
            tabsNavData.map((data) => (
              <HomeTabsNavCard
                onClick={() => handleTabSelect(data.id)}
                key={data.id}
                badgeText={data?.badgeText}
                imageSrc={data.imageSrc}
                imageDescription={data.imageDescription}
                isActive={data.id === activeTab}
                extraClass={data.id === activeTab ? "cf-active" : "cf-inactive"}
              />
            ))}
        </div>
        {tabsNavContainerData && (
          <HomeTabsNavDataCard
            key={tabsNavContainerData[activeTab].id}
            title={tabsNavContainerData[activeTab].title}
            description={tabsNavContainerData[activeTab].description}
            btnTitle={tabsNavContainerData[activeTab].btnTitle}
            btnLink={tabsNavContainerData[activeTab].btnLink}
            imageSrc={tabsNavContainerData[activeTab].imageSrc}
            showCTAs={showCTAs}
          />
        )}
      </div>
    </section>
  );
}
HomeFeatureTabs.propTypes = {
  tabsNavData: PropTypes.array,
  tabsNavContainerData: PropTypes.any,
  showCTAs: PropTypes.bool,
};
