import PropTypes from "prop-types";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";

const CustomLinkTag = ({
  to,
  onClick,
  children,
  classNames,
  restrictRedirection = false,
  useAnchorTag = false,
}) => {
  CustomLinkTag.propTypes = {
    to: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
    classNames: PropTypes.string,
    restrictRedirection: PropTypes.bool,
    useAnchorTag: PropTypes.bool,
  };
  const navigate = useNavigate();
  const [isVisibleDialog, setVisibleDialog] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (useAnchorTag) {
      onClick && onClick();
      window.location.href = to;
    } else if (restrictRedirection) {
      setVisibleDialog(true);
    } else {
      onClick && onClick();
      navigate(to);
    }
  };

  const handleConfirm = () => {
    setVisibleDialog(false);
    onClick && onClick();
    navigate(to);
  };

  return (
    <>
      {location.pathname != to ? (
        <Link className={`${classNames ?? ""} `} to={to} onClick={handleClick}>
          {children}
        </Link>
      ) : (
        <span
          className={`${classNames ?? ""} ${
            location.pathname === to ? "nav-item-active" : ""
          } default-cursor`}
        >
          {children}{" "}
        </span>
      )}

      {restrictRedirection && (
        <Modal
          centered
          show={isVisibleDialog}
          onHide={() => setVisibleDialog(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-0 pb-1">
            <h5>Warning!</h5>
          </Modal.Header>
          <Modal.Body>
            Your progress will be lost if you leave this page.
          </Modal.Body>
          <Modal.Footer className="justify-content-between border-0">
            <button
              className="btn btn-outline-primary"
              onClick={() => setVisibleDialog(false)}
            >
              Cancel
            </button>
            <button className="btn btn-outline-danger" onClick={handleConfirm}>
              Leave
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CustomLinkTag;
