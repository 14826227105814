import { getAnalytics, logEvent } from "@firebase/analytics";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { fStore } from "../../../firebase";
const analytics = getAnalytics();

const logAnalyticsClickEvent = ({ api_call_type, error_msg }) => {
  logEvent(analytics, "firebase_failure", {
    api_call_type: api_call_type,
    error_message: error_msg,
  });
};

export async function addDataAtL1CollectionFirestore({
  demo_request_data,
  fstore_collection_name,
}) {
  try {
    const collectionPath = collection(fStore, fstore_collection_name);
    const dataWithTimestamp = {
      ...demo_request_data,
      timestamp: serverTimestamp(),
    };
    await addDoc(collectionPath, dataWithTimestamp);
  } catch (error) {
    console.error("Error adding demo request: ", error);
    logAnalyticsClickEvent({
      api_call_type: fstore_collection_name,
      error_msg: error.message,
    });
  }
}

export async function addDataAtL2CollectionFStore({
  data,
  parent_collection,
  parent_document,
  child_collection,
}) {
  console.log(
    "DWARAKA addDataAtL2CollectionFStore: ",
    data,
    parent_collection,
    parent_document,
    child_collection
  );
  const collectionPath = collection(
    fStore,
    parent_collection,
    parent_document,
    child_collection
  );

  try {
    let docRef;
    const dataWithTimestamp = {
      ...data,
      timestamp: serverTimestamp(),
    };
    docRef = await addDoc(collectionPath, dataWithTimestamp);
    console.log("Resume document added successfully. Document ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error performing document operation: ", error);
    logAnalyticsClickEvent({
      api_call_type: "adding_online_test_submission",
      error_msg: error.message,
    });
    return null;
  }
}

export async function overrideVariableATL1orL2CollectionFStore({
  parent_collection,
  parent_document,
  child_collection,
  child_document,
  variableToUpdate,
  updatedValue,
}) {
  var documentRef;
  console.log(
    "DWARAKA subscription updateFirestoreVariable: ",
    parent_collection,
    parent_document,
    child_collection,
    child_document,
    variableToUpdate,
    updatedValue
  );
  try {
    if (child_collection) {
      documentRef = doc(
        fStore,
        parent_collection,
        parent_document,
        child_collection,
        child_document
      );
    } else {
      documentRef = doc(fStore, parent_collection, parent_document);
    }

    try {
      const docSnapshot = await getDoc(documentRef);

      if (docSnapshot.exists()) {
        // Document exists, update it
        await updateDoc(documentRef, {
          [variableToUpdate]: updatedValue,
        });
        console.log("Document updated successfully");
      } else {
        // Document doesn't exist, add a new document with the provided ID
        await setDoc(documentRef, {
          [variableToUpdate]: updatedValue,
        });
        console.log("New document added successfully");
      }
    } catch (error) {
      console.error("Error updating/adding document: ", error);
    }
  } catch (error) {
    console.error("Error creating document reference: ", error);
  }
}

export async function updateVariableATL1CollectionFStore({
  data,
  collectionName,
  documentName,
  variableName,
}) {
  try {
    const collectionPath = collection(fStore, collectionName);
    const documentRef = doc(collectionPath, documentName);
    const docSnap = await getDoc(documentRef);

    if (docSnap.exists()) {
      const existingData = docSnap.data();
      const currentVariableValue = existingData[variableName] || [];

      const updatedVariableValue = [data, ...currentVariableValue];

      await updateDoc(documentRef, {
        [variableName]: updatedVariableValue,
      });
    } else {
      await setDoc(documentRef, {
        [variableName]: [data],
      });
    }
  } catch (error) {
    console.error("Error updating variable at L1 collection: ", error);
    logAnalyticsClickEvent({
      api_call_type: `l1_collection_variable_update_failure_for_${documentName}`,
      error_msg: error.message,
    });
  }
}

export async function findVariableIfATL1CollectionFirestore({
  data,
  collectionName,
  documentName,
  variableName,
}) {
  try {
    const collectionPath = collection(fStore, collectionName);
    const documentRef = doc(collectionPath, documentName);
    const docSnap = await getDoc(documentRef);

    if (docSnap.exists()) {
      const existingData = docSnap.data();
      const currentVariableValue = existingData[variableName] || [];
      if (currentVariableValue.includes(data)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error updating variable at L1 collection: ", error);
    logAnalyticsClickEvent({
      api_call_type: `l1_collection_variable_update_failure_for_${documentName}`,
      error_msg: error.message,
    });
  }
}

export async function fetchAllDocsAtL2Firestore({
  parent_collection,
  parent_document,
  child_collection,
}) {
  try {
    const querySnapshot = await getDocs(
      collection(fStore, parent_collection, parent_document, child_collection)
    );
    const submissions = [];
    querySnapshot.forEach((doc) => {
      const dataWithTimestamp = {
        ...doc.data(),
        id: doc.id,
      };
      submissions.push(dataWithTimestamp);
    });
    if (submissions.length > 0) {
      return submissions;
    } else return null;
  } catch (error) {
    console.error("Error fetching interview submissions: ", error);
    return null;
  }
}
