const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_USER_DATA":
      // Merge the new data with the existing state
      return {
        ...state,
        ...action.payload,
      };
    case "UPDATE_USER_FIELD": {
      const { fieldName, fieldValue, increment } = action.payload;

      // Check if the field exists in the state, and if not, create it
      if (!Object.prototype.hasOwnProperty.call(state, fieldName)) {
        return {
          ...state,
          [fieldName]: fieldValue,
        };
      }

      // If 'increment' is true, increase the field's value by 'fieldValue'
      if (increment) {
        return {
          ...state,
          [fieldName]: state[fieldName] + fieldValue,
        };
      }

      // If the field exists, update it
      return {
        ...state,
        [fieldName]: fieldValue,
      };
    }
    // Handle other cases or actions here if needed
    case "RESET_USER_STATE":
      // Reset the state to the initial state (empty object)
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
