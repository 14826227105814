import React from "react";
import Lottie from "react-lottie";
import BPFooter from "../../../BusinessPublic/layouts/BPFooter/BPFooter";
import BPNavBar from "../../../BusinessPublic/layouts/BPNavBar/BPNavBar";
import pageNotFoundAnimation from "../../assets/LottieFiles/pageNotFoundAnimation.json";
import PageHelmet from "../../components/PageHelmet/PageHelmet";
import useIsTabletSize from "../../customHooks/isTabletSizeHook";
import "./Error404Page.css";

export default function Error404Page() {
  const isTabletSize = useIsTabletSize();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pageNotFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <PageHelmet
        title={"EvaluAItor: 404 page"}
        description={
          "Elevate your interview skills with AI. Master software engineering interviews with AI-driven mock interviews, personalized feedback, and instant insights"
        }
      />
      <BPNavBar sticky={true} />
      <div className="error-404-page">
        <div className="centered-error-404-div p-events-none">
          <Lottie options={defaultOptions} height={500} width={500} />
        </div>
        <BPFooter isTabletSize={isTabletSize} />
      </div>
    </div>
  );
}
