import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDV800PNdA7D1tMdaBtuRsu5VbZDk9a8Ow",
  authDomain: "evaluaitor.firebaseapp.com",
  databaseURL:
    "https://evaluaitor-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "evaluaitor",
  storageBucket: "evaluaitor.appspot.com",
  messagingSenderId: "513264712290",
  appId: "1:513264712290:web:2b315866f0b3106ba2af3b",
  measurementId: "G-N3HKXKYBLX",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getDatabase(app);
export const fStore = getFirestore(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
