import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { findIfThisDataInRealTimeDB } from "../../../Consumer/supportFunctions/FirebaseFunctions";

const AdminRoute = ({ children }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const handleAuthStateChanged = async (user) => {
    if (!user || !user.uid) {
      dispatch({
        type: "LOGIN_REDIRECT",
        payload: pathname,
      });
      navigate("/login");
    } else {
      var isAdmin = await findIfThisDataInRealTimeDB(
        user.uid,
        "AllAdminsUserIdList"
      );
      if (!isAdmin) {
        navigate("/");
        alert("You are not authorized to access this page."); // Alert user
      }
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChanged);

    return () => unsubscribe(); // Cleanup on component unmount
  }, [auth, navigate, handleAuthStateChanged]);

  return children;
};
export default AdminRoute;
