import { getAnalytics, logEvent } from "@firebase/analytics";
import {
  child,
  get,
  push,
  ref as ref1,
  remove,
  set,
  update,
} from "firebase/database";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { updateVariableATL1CollectionFStore } from "../../Global/utils/firebaseFunctions/firestoreFunctions";
import { db, fStore } from "../../firebase";
const analytics = getAnalytics();

export const logAnalyticsClickEvent = ({ api_call_type, error_msg }) => {
  logEvent(analytics, "firebase_failure", {
    api_call_type: api_call_type,
    error_message: error_msg,
  });
};

export async function addNewUserReferralCodeToAllUserReferralList(
  referarlId,
  userId,
  realTimeDBPath
) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    // Check if the email already exists in the list
    var emailMap = {};
    if (snapshot.exists()) {
      emailMap = snapshot.val() || {};

      if (!emailMap[referarlId]) {
        emailMap[referarlId] = userId;

        // Use 'set' to update the data in the database
        await set(ref1(db, realTimeDBPath), emailMap);
      } else {
        console.log(
          "Email already exists in EmailSubscriptionList:",
          referarlId
        );
      }
    } else {
      emailMap[referarlId] = userId;
      await set(ref1(db, realTimeDBPath), emailMap);
    }
  } catch (error) {
    console.error("Error adding email to subscription list:", error);
  }
}

export async function fetchAllReportedObjectiveQuestions() {
  const realTimeDBPath = "AllReportedObjectiveQuestionsList";

  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const reportedQuestions = snapshot.val();
      const promises = Object.entries(reportedQuestions).map(
        async ([questionId, reportData]) => {
          console.log("DWaraka Siri questionId: ", questionId);
          console.log("DWaraka Siri reportData: ", reportData);
          try {
            const firestoreCollectionName = `${questionId.replace(
              /\d+$/,
              ""
            )}question_bank`;
            const firestoreDocumentName = questionId;
            const docRef = doc(
              fStore,
              firestoreCollectionName,
              firestoreDocumentName
            );
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              const questionData = docSnapshot.data();
              return {
                questionId,
                reportData,
                questionData,
              };
            } else {
              console.log(`Document ${questionId} does not exist`);
              return {
                questionId,
                reportData,
                questionData: null,
              };
            }
          } catch (error) {
            console.error(`Error fetching document ${questionId} `, error);
            return {
              questionId,
              reportData,
              questionData: null,
            };
          }
        }
      );
      return Promise.all(promises);
    } else {
      console.log("No reported questions found in the database.");
      return [];
    }
  } catch (error) {
    console.error(
      "Error fetching reported questions from Realtime Database:",
      error
    );
    return [];
  }
}

export async function fetchValueFromRealTimeDB(realTimeDBPath) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return null; // or any other default value you want to return when the path doesn't exist
    }
  } catch (error) {
    console.error("Error fetching data from Realtime DB:", error);
    return null; // or any other default value you want to return in case of an error
  }
}

export async function fetchProfileIdFromRealTimeDB({
  realTimeDBPath,
  userUniqueId,
}) {
  const userProfileIdRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(userProfileIdRef);

    if (snapshot.exists()) {
      const userProfileIds = snapshot.val();
      for (const key in userProfileIds) {
        if (
          Object.prototype.hasOwnProperty.call(userProfileIds, key) &&
          userProfileIds[key] === userUniqueId
        ) {
          return key; // Return the profileId corresponding to the userUniqueId
        }
      }
    }

    return null; // Return null if the userUniqueId is not found or the path doesn't exist
  } catch (error) {
    console.error("Error fetching data from Realtime DB:", error);
    return null; // Return null in case of an error
  }
}

export async function findIfThisDataInRealTimeDB(data, realTimeDBPath) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const emailList = snapshot.val() || {};
      return emailList[data];
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error finding data in Realtime DB:", error);
    return false;
  }
}

export async function addDataToRealTimeDatabase(data, realTimeDBPath, userId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (!userId) {
        const newKey = push(ref1(db, realTimeDBPath), {
          ...data,
        }).key;
        resolve(newKey);
      } else {
        // Check if the userId already exists in the database
        const keyRef = child(ref1(db, realTimeDBPath), userId);
        const snapshot = await get(keyRef);

        if (snapshot.exists()) {
          // If the userId exists, do nothing and resolve with the existing userId
          resolve(userId);
        } else {
          // If the key doesn't exist, update the data at that key
          await update(ref1(db, `${realTimeDBPath}/${userId}`), data);
          resolve(userId);
        }
      }
    } catch (error) {
      console.error("Error updating value:", error);
      reject(error);
    }
  });
}

export async function editDataInRealTimeDatabase(
  updatedValue,
  variablePath,
  realTimeDBPath,
  userId
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (!userId) {
        reject(new Error("User ID is required to edit data."));
        return;
      }

      // Check if the userId exists in the database
      const keyRef = child(ref1(db, realTimeDBPath), userId);
      const snapshot = await get(keyRef);

      if (snapshot.exists()) {
        // If the userId exists, construct the path to the specific variable
        const variablePathObject = {};
        variablePathObject[variablePath] = updatedValue;
        // Update the specific variable with the updated value
        await update(
          ref1(db, `${realTimeDBPath}/${userId}`),
          variablePathObject
        );
        resolve(userId);
      } else {
        // If the userId does not exist, reject with an error
        reject(new Error("User ID not found in the database."));
      }
    } catch (error) {
      console.error("Error editing value:", error);
      reject(error);
    }
  });
}
export async function saveThisInterviewScoreToLastThreeInterviewsList(
  userId,
  thisInterviewId,
  updatedInterviewScore,
  userProfileId
) {
  try {
    if (!userId) {
      throw new Error("User ID is required to edit data.");
    }

    // Get a reference to the AllUsersData node for the user
    const userRef = child(ref1(db, "AllUsersData"), userId);

    // Check if the userId exists in the database
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      // Get the existing lastThreeInterviewScores
      let existingData = snapshot.val().lastThreeInterviewScores || {};

      // Generate a timestamp for the new entry
      const timestamp = new Date().getTime();

      // Update the score and timestamp or add a new entry
      existingData[thisInterviewId] = {
        score: updatedInterviewScore,
        timestamp: timestamp,
      };

      // Check if the number of entries exceeds 3
      const entryKeys = Object.keys(existingData);
      if (entryKeys.length > 3) {
        // Sort the keys by timestamp and remove the oldest entry
        entryKeys.sort(
          (a, b) => existingData[a].timestamp - existingData[b].timestamp
        );
        const oldestEntryKey = entryKeys[0];
        delete existingData[oldestEntryKey];
      }

      // Set the updated data
      await update(userRef, { lastThreeInterviewScores: existingData });

      // After updating the lastThreeInterviewScores
      const updatedLastThreeInterviewsData = Object.values(existingData);

      // Check if there are at least 2 scores to compare
      if (updatedLastThreeInterviewsData.length >= 2) {
        const latestScore =
          updatedLastThreeInterviewsData[
            updatedLastThreeInterviewsData.length - 1
          ].score;
        const secondLatestScore =
          updatedLastThreeInterviewsData[
            updatedLastThreeInterviewsData.length - 2
          ].score;
        console.log(
          "Dwaraka Latest two scores: ",
          latestScore,
          secondLatestScore
        );
        if (
          latestScore > 6 &&
          secondLatestScore > 6 &&
          !snapshot.val().isEligibleForJobReferral
        ) {
          const earnedJobReferralNotification = {
            title: "Congratulations - Earned a job referral & mentorship",
            description: `You are now eligible for a job referral and free mentorship as you've scored good in last two interviews.`,
            notificationImageUrl:
              "https://firebasestorage.googleapis.com/v0/b/evaluaitor.appspot.com/o/ImagesForWebsite%2Flogo_s_bg_blue.png?alt=media&token=173283c0-1799-468e-8a17-f7981535745b",
            redirectUrl: `/user-dashboard/${userProfileId}`,
            timeStamp: new Date(),
          };
          await incrementUserCredits(
            "onboarding",
            "showEarnedJobReferralModel",
            true,
            userId
          );
          await incrementUserCredits(
            "onboarding",
            "isEligibleForJobReferral",
            true,
            userId
          );
          await updateVariableATL1CollectionFStore({
            data: earnedJobReferralNotification,
            collectionName: "all-user-notifications-data",
            documentName: userId,
            variableName: "Notifications",
          });
          await incrementUserCredits(
            "notifications",
            "unSeenNotificationsCount",
            1,
            userId
          );
          console.log("Dwaraka Latest two scores are more than 6");
        }
      }

      return true; // Return true to indicate success
    } else {
      console.error("User ID not found in the database.");
      return false; // Return false to indicate failure
    }
  } catch (error) {
    console.error("Error editing value:", error);
    throw error; // Re-throw the error to propagate it
  }
}

export async function findIfUserAttemptedThisPotd(
  variablePath,
  variableToCheck,
  userId
) {
  try {
    if (!userId) {
      throw new Error(
        "User ID is required to find if DIP is solved previously."
      );
    }

    const keyRef = child(ref1(db, "AllUsersData"), userId);
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      const existingArray = snapshot.val()[variablePath] || [];
      if (existingArray.includes(variableToCheck)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false; // User data doesn't exist
    }
  } catch (error) {
    console.error("Error editing value:", error);
    throw error; // Re-throw the error to be caught by the caller
  }
}

export async function incrementUserCredits(
  source,
  variableToChange,
  incrementBy,
  userId
) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (!userId) {
        reject(new Error("User ID is required to edit data."));
        return;
      }

      // Check if the userId exists in the database
      const keyRef = child(ref1(db, "AllUsersData"), userId);
      const snapshot = await get(keyRef);
      const variablePathObject = {};

      if (snapshot.exists()) {
        // If the userId exists, construct the path to the specific variable
        if (source == "linkedin") {
          if (snapshot.val().linkedinLinked) {
            resolve(userId);
            return;
          } else {
            variablePathObject["linkedinLinked"] = true;
          }
        } else if (source == "userSpecifReferralArrayUpdate") {
          if (userId == incrementBy) {
            return;
          }
          const existingArray = snapshot.val().referredToUsersList || [];
          if (!existingArray.includes(incrementBy)) {
            variablePathObject["referredToUsersList"] = [
              ...existingArray,
              incrementBy,
            ];
          }
        } else if (source == "userSpecifsubmittedPotdsListUpdate") {
          const existingArray = snapshot.val().submittedDIPsList || [];
          console.log("DWarkaaexistingArray: ", existingArray);
          console.log("DWarka incrementBy: ", incrementBy);
          if (!existingArray.includes(incrementBy)) {
            variablePathObject["submittedDIPsList"] = [
              ...existingArray,
              incrementBy,
            ];
          }
        }

        if (variableToChange == "credits") {
          variablePathObject[variableToChange] =
            snapshot.val().credits + incrementBy;
        } else if (variableToChange == "totalNumberOfInterviews") {
          variablePathObject[variableToChange] =
            snapshot.val().totalNumberOfInterviews + incrementBy;
        } else if (variableToChange == "averageInterviewScore") {
          variablePathObject[variableToChange] =
            snapshot.val().averageInterviewScore + incrementBy;
        } else if (variableToChange == "bestInterviewScore") {
          variablePathObject[variableToChange] =
            snapshot.val().bestInterviewScore + incrementBy;
        } else if (variableToChange == "accumulatedTheoryScore") {
          variablePathObject[variableToChange] =
            snapshot.val().accumulatedTheoryScore + incrementBy;
        } else if (variableToChange == "accumulatedResumeScore") {
          variablePathObject[variableToChange] =
            snapshot.val().accumulatedResumeScore + incrementBy;
        } else if (variableToChange == "accumulatedDSAScore") {
          const currentAccumulatedDSAScore =
            snapshot.val().accumulatedDSAScore || 0;
          variablePathObject[variableToChange] =
            currentAccumulatedDSAScore + incrementBy;
        } else if (variableToChange == "numberOfDIPSubmissions") {
          const previousValue = snapshot.val().numberOfDIPSubmissions || 0;
          variablePathObject[variableToChange] = previousValue + incrementBy;
        } else if (variableToChange == "unSeenNotificationsCount") {
          const previousValue = snapshot.val().unSeenNotificationsCount || 0;
          variablePathObject[variableToChange] = previousValue + incrementBy;
        } else if (variableToChange == "showSignInOnboardingModel") {
          variablePathObject[variableToChange] = incrementBy;
        } else if (variableToChange == "showEarnedCreditsOnboardingModel") {
          variablePathObject[variableToChange] = incrementBy;
        } else if (variableToChange == "showEarnedJobReferralModel") {
          variablePathObject[variableToChange] = incrementBy;
        } else if (variableToChange == "isEligibleForJobReferral") {
          variablePathObject[variableToChange] = incrementBy;
        }

        // Update the specific variable with the updated value
        await update(
          ref1(db, `${"AllUsersData"}/${userId}`),
          variablePathObject
        );
        resolve(userId);
      } else {
        variablePathObject[variableToChange] = incrementBy;
        await update(
          ref1(db, `${"AllUsersData"}/${userId}`),
          variablePathObject
        );
        resolve(userId);
      }
    } catch (error) {
      console.error("Error editing value:", error);
      // reject(error);
    }
  });
}

export function getDataFromRealtimeDatabase(realtimeDataPath) {
  const todoRef = ref1(db, realtimeDataPath);
  return new Promise((resolve, reject) => {
    get(todoRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data); // Resolve with the retrieved data
        } else {
          resolve(null); // Resolve with null if no data available
        }
      })
      .catch((error) => {
        reject(error); // Reject with the error if any occurs
      });
  });
}

export async function deleteDataFromRealtimeDatabase(realtimeDataPath) {
  const todoRef = ref1(db, realtimeDataPath);

  try {
    console.log("DWaraka realtime database: ", realtimeDataPath);
    // Attempt to remove the data from the Realtime Database
    await remove(todoRef);

    console.log("Data successfully deleted from Realtime Database");
    return null; // No error, return null
  } catch (error) {
    console.error("Error deleting data from Realtime Database:", error);
    return error; // Return the error
  }
}

export async function fetchresume_evaluaition_userIdsList(realTimeDBPath) {
  const keyRef = ref1(db, realTimeDBPath);
  try {
    const snapshot = await get(keyRef);

    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error finding data in Realtime DB:", error);
    return false;
  }
}

export async function fetchDocumentFromFireStore({
  parent_collection,
  parent_document,
  child_collection,
  child_document,
}) {
  var documentRef;
  // console.log(
  //   "DWaraka: ",
  //   parent_collection,
  //   parent_document,
  //   child_collection,
  //   child_document
  // );
  try {
    if (child_collection) {
      documentRef = doc(
        fStore,
        parent_collection,
        parent_document,
        child_collection,
        child_document
      );
    } else {
      documentRef = doc(fStore, parent_collection, parent_document);
    }
    const docSnap = await getDoc(documentRef);
    if (docSnap.exists()) {
      const datanew = docSnap.data();
      return datanew;
    } else {
      console.log("Document does not exist");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    return null;
  }
}

export async function deleteDocumentFromFirestore({
  parent_collection,
  parent_document,
  child_collection,
  child_document,
}) {
  var documentRef;
  console.log(
    "DWaraka: ",
    parent_collection,
    parent_document,
    child_collection,
    child_document
  );

  try {
    if (child_collection) {
      documentRef = doc(
        fStore,
        parent_collection,
        parent_document,
        child_collection,
        child_document
      );
    } else {
      documentRef = doc(fStore, parent_collection, parent_document);
    }

    // Delete the document
    await deleteDoc(documentRef);

    console.log("Document successfully deleted");
    return null; // No error, return null
  } catch (error) {
    console.error("Error deleting document:", error);
    return error; // Return the error
  }
}

export async function fetchAllResumeEvaluaitionsData() {
  try {
    const q = collection(fStore, "resume_evaluaitions");
    const querySnapshot = await getDocs(q);
    const documentIds = querySnapshot.docs.map((doc) => doc.id);
    return documentIds;
  } catch (error) {
    console.error("Error fetching document IDs: ", error);
    return [];
  }
}

export async function fetchUserAllPackData({ userUid, collectionName }) {
  try {
    const q = query(
      collection(fStore, collectionName, userUid, "evaluAItor"),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(q);
    const fetchedGifts = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return fetchedGifts;
  } catch (error) {
    console.error("Error fetching gifts: ", error);
    return [];
  }
}

export async function fetchUserAllPendingPackData({ userUid, collectionName }) {
  try {
    const q = query(
      collection(fStore, collectionName, userUid, "evaluAItor"),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(q);
    const fetchedGifts = querySnapshot.docs
      .filter((doc) => doc.data().isThisEntirelyDone != true)
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    return fetchedGifts;
  } catch (error) {
    console.error("Error fetching gifts: ", error);
    return [];
  }
}

export async function fetchAllDocumentsDataFromCollection({
  parent_collection,
  parent_document,
  collectionName,
}) {
  try {
    let collectionRef;
    if (parent_collection) {
      collectionRef = collection(
        fStore,
        parent_collection,
        parent_document,
        collectionName
      );
    } else {
      collectionRef = collection(fStore, collectionName);
    }
    const collectionQuery = query(collectionRef, orderBy("timestamp", "desc"));
    const querySnapshot = await getDocs(collectionQuery);
    const documentsData = [];
    querySnapshot.forEach((doc) => {
      const documentData = {
        id: doc.id,
        ...doc.data(),
      };
      documentsData.push(documentData);
    });

    return documentsData;
  } catch (error) {
    console.error("Error fetching documents from collection:", error);
    return null;
  }
}
